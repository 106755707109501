let venoBoxPromise;
function getVenoBox() {
  if (!venoBoxPromise) {
    venoBoxPromise = import('venobox').then(({ default: VenoBox }) => {
      return VenoBox;
    });
  }

  return venoBoxPromise;
}
let masonryPromise;
function getMasonry() {
  if (!masonryPromise) {
    masonryPromise = import('minimasonry').then(({ default: MiniMasonry }) => {
      return MiniMasonry;
    });
  }

  return masonryPromise;
}

export function onRouteUpdate() {
  handleLightbox();
  handleMasonry();
}

function handleLightbox() {
  const hasVenoboxCss = !!document.getElementById('venobox');
  if (!document.querySelector('.lightbox')) {
    if (hasVenoboxCss) {
      document.head.removeChild(document.getElementById('venobox'));
    }

    return;
  }

  getVenoBox().then((VenoBox) => {
    new VenoBox({
      selector: '.lightbox',
    });
  });

  if (!hasVenoboxCss) {
    import('!!file-loader!venobox/dist/venobox.min.css').then(
      ({ default: filepath }) => {
        const stylesheet = document.createElement('link');
        stylesheet.rel = 'stylesheet';
        stylesheet.href = filepath;
        stylesheet.id = 'venobox';

        requestAnimationFrame(() => {
          document.head.appendChild(stylesheet);
        });
      }
    );
  }
}

function handleMasonry() {
  let masonry;
  const masonryEl = document.querySelector('.masonry');
  if (!masonryEl) {
    if (masonry) {
      masonry.destroy();
      masonry = null;
    }
    return;
  }

  getMasonry().then((MiniMasonry) => {
    masonryEl.setAttribute('data-masonry', 'true');

    masonry = new MiniMasonry({
      container: '.masonry',
      gutter: 5,
      basewidth: 297,
      surroundingGutter: false,
    });
  });
}
