module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Van Woensel Woningbouw","short_name":"Van Woensel Woningbouw","icon":"src/assets/favicon.svg","start_url":"/","background_color":"#111","theme_color":"#a2466c","display":"minimal-ui","theme_color_in_head":false,"cache_busting_mode":"name","legacy":false,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fff2b7ca1c3f3b341f7f3833b932fb37"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
